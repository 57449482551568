<template>
  <div id="nav-bar">
    <div class="logo">
      <router-link to="/"
        ><mdicon name="access-point-network" width="40" height="40"
      /></router-link>
    </div>
    <div class="links">
      <router-link to="/appareils"><mdicon name="devices"/></router-link>
      <router-link to="/favorites"><mdicon name="heart-outline"/></router-link>
      <router-link to="/settings"><mdicon name="cog-outline"/></router-link>
      <div
        id="indicator"
        :style="`top: ${itemSize * indicator_pos}%; height: ${itemSize}%`"
        :class="indicator_hidden && 'hidden'"
      ></div>
    </div>
    <div class="info">
      <button @click="modale = true">
        <mdicon name="information-outline" />
      </button>
    </div>

    <transition name="fade">
      <div v-if="modale" id="info-modale">
        <div
          v-click-outside="
            () => {
              modale = false
            }
          "
        >
          <button class="close" @click="modale = false">
            <mdicon name="close" width="23" height="23" />
          </button>
          <h1>Projet IOT Meteo</h1>
          <p>
            Ce projet a été réalisé dans le cadre du cours d'IOT de la MIAGE,
            avec l'enseignant Gilles Menez.
          </p>
          <p>
            <ul>
              <li>Samuel Maugard</li>
              <li>Sabrina Mercuri</li>
              <li>Sacha Bydon</li>
              <li>Stephane Desire</li>
              <li>Allan Duvois</li>
            </ul>
          </p>
          <div class="actions">
            <a
              href="https://github.com/SachaBydon/projet-iot-meteo"
              target="_blank"
              title="Github"
              ><mdicon name="github" width="30" height="30"
            /></a>
            <a
              href="https://sachabydon.github.io/projet-iot-meteo"
              target="_blank"
              title="Documentation"
              ><mdicon name="file-document" width="30" height="30"
            /></a>
            <a
              href="https://stats.uptimerobot.com/mX0Y7I5xmx"
              target="_blank"
              title="Status du serveur"
              ><mdicon name="access-point" width="30" height="30"
            /></a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  data: () => ({
    indicator_pos: -1,
    indicator_hidden: false,
    routes: ['Appareils', 'Favorites', 'Settings'],
    itemSize: 0,
    modale: false,
  }),
  directives: { clickOutside: vClickOutside.directive },

  methods: {
    update_indicator() {
      const id = this.routes.findIndex((r) => r === this.$route.name)
      this.indicator_hidden = id === -1
      this.indicator_pos = id === -1 ? 0 : id
    },
  },
  beforeMount() {
    this.itemSize = (1 / this.routes.length) * 100
    this.update_indicator()
  },
  watch: {
    $route() {
      this.update_indicator()
    },
  },
}
</script>

<style lang="scss" scoped>
$navbar-width: 80px;

#nav-bar {
  width: $navbar-width;
  border-right: var(--border);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    width: 100%;
    height: $navbar-width;
    display: grid;
    place-items: center;
    a {
      color: var(--primary);
      border-radius: 99px;
      padding: 10px;
      transition: all ease-in-out 0.2s;
      &:hover {
        color: var(--white);
        background-color: var(--dark-light);
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    & > a {
      width: 100%;
      padding: 20px 0;
      text-align: center;
      color: var(--grey);
      transition: all ease-in-out 0.15s;
      outline: none;

      &.router-link-exact-active {
        color: var(--white);
      }

      &:hover {
        color: var(--white);
        & > span {
          background: var(--dark-light);
          border-radius: 99px;
          padding: 10px;
        }
      }
    }

    #indicator {
      position: absolute;
      background-color: var(--primary);
      width: 3px;
      right: -2.5px;
      border-radius: 9px;
      transition: all ease-in-out 0.2s;

      &.hidden {
        opacity: 0;
      }
    }
  }

  .info {
    color: var(--grey);
    width: 100%;
    height: $navbar-width;
    display: grid;
    place-items: center;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      color: var(--grey);
      cursor: pointer;
      transition: all ease-in-out 0.15s;

      &:hover {
        background-color: var(--dark-light);
        border-radius: 99px;
        padding: 10px;
        color: var(--white);
      }
    }
  }
}

#info-modale {
  position: absolute;
  padding: 20px;
  // background: var(--background);
  background: rgba(black, 0.3);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  & > div {
    position: relative;
    padding: 32px;
    border-radius: 16px;
    width: 100%;
    max-width: 400px;

    background: var(--dark);
    border: 2px solid var(--dark-light);
    box-shadow: 0 0 5px var(--grey);

    h1 {
      text-align: center;
    }

    .actions a {
      color: var(--primary);
      margin-right: 8px;
    }

    .close {
      all: unset;
      cursor: pointer;
      border-radius: 10px;
      padding: 8px;
      position: absolute;
      top: 8px;
      right: 8px;
      transition: all ease-in-out .2s;

      &:hover {
        background: var(--dark-light);
      }
    }

    ul {
      padding: 0;
      margin: 0;
      font-size: 12px;
      user-select: none;
      li {
        user-select: all;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 8px;
        background: var(--dark-light);
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
