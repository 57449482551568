<template>
  <div>
    <h1>Paramètres</h1>
    <ToggleNightMode style="margin: 16px;"/>
  </div>
</template>

<script>
import ToggleNightMode from '@/components/ToggleNightMode.vue'
export default {
  components: {
    ToggleNightMode,
  },
}
</script>

<style></style>
