<template>
  <div id="worldChart" ref="chartdiv"></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4themes_material from '@amcharts/amcharts4/themes/material'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'

am4core.useTheme(am4themes_material)
am4core.useTheme(am4themes_animated)

export default {
  data: () => ({
    data: [],
  }),
  mounted() {
    am4core.ready(() => {
      const colorSet = new am4core.ColorSet()
      this.data = this.$store.state.data.map((a) => ({
        _id: a._id,
        longitude: a.coord.lon,
        latitude: a.coord.lat,
        nom: a.nom,
        color: colorSet.next().hex,
        title: `${a.nom} | ${a.coord.city}`,
      }))
      this.init_chart()
    })
  },
  beforeDestroy() {
    if (this.chart) this.chart.dispose()
  },
  methods: {

    /**
     * Init map chart
     */
    init_chart() {
      const chart = am4core.create(this.$refs.chartdiv, am4maps.MapChart)

      chart.homeZoomLevel = 1.1
      chart.geodata = am4geodata_worldLow
      chart.projection = new am4maps.projections.Miller()

      const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
      polygonSeries.exclude = ['AQ']
      polygonSeries.useGeodata = true

      const imageSeries = chart.series.push(new am4maps.MapImageSeries())
      imageSeries.mapImages.template.propertyFields.longitude = 'longitude'
      imageSeries.mapImages.template.propertyFields.latitude = 'latitude'
      imageSeries.mapImages.template.tooltipHTML =
        '<div class="tooltip">{title}</div>'
      imageSeries.tooltip.background.opacity = 0

      const polygonTemplate = polygonSeries.mapPolygons.template
      polygonTemplate.strokeWidth = 1
      if (this.$store.state.dark) {
        polygonTemplate.fill = am4core.color('#343438')
        polygonTemplate.stroke = am4core.color('#6b6b6e')
      }

      const circle = imageSeries.mapImages.template.createChild(am4core.Circle)
      circle.radius = 5
      circle.propertyFields.fill = 'color'
      circle.nonScaling = true

      const circle2 = imageSeries.mapImages.template.createChild(am4core.Circle)
      circle2.radius = 5
      circle2.propertyFields.fill = 'color'

      circle2.events.on('inited', function(event) {
        animateBullet(event.target)
      })

      function animateBullet(circle) {
        const zoom = 1 / chart.zoomLevel
        const animation = circle.animate(
          [
            { property: 'opacity', from: 1, to: 0 },
            { property: 'scale', from: zoom, to: zoom * 4 },
          ],
          1000,
          am4core.ease.circleOut
        )
        animation.events.on('animationended', function(event) {
          animateBullet(event.target.object)
        })
      }

      circle2.events.on('hit', (event) => {
        const item = event.target._dataItem.dataContext
        console.log(item)
        this.$router.push('/appareils/' + item._id)
      })

      imageSeries.data = this.data

      this.chart = chart
    },
  },
  watch: {
    '$store.state.dark'() {
      this.chart.dispose()
      this.init_chart()
    },
  },
}
</script>

<style lang="scss">
#worldChart {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  circle {
    cursor: pointer;
  }

  [aria-labelledby] {
    display: none;
  }

  .tooltip {
    background: var(--dark-light);
    color: var(--white);
    border-radius: 999px;
    box-shadow: 0 0 5px var(--white);
    padding: 8px 16px;
    margin: 5px;
  }
}
</style>
