<template>
  <div class="favoris" v-dragscroll>
    <h1 class="title">Favoris</h1>
    <div class="list">
      <Appareil
        v-for="(item, i) in favoris"
        v-bind:key="i"
        :appareil="item"
        :index="i"
        @like="like(i)"
      />
    </div>
  </div>
</template>

<script>
import Appareil from '@/components/Appareil.vue'
import { dragscroll } from 'vue-dragscroll'

export default {
  components: { Appareil },
  data: () => ({
    favoris: [],
  }),
  beforeMount() {
    this.favoris = this.$store.state.data.filter((a) => a.favoris === true)
  },
  methods: {
    /**
     * Toggle favorite item
     * @param { Number } id appareil index
     */
    like(id) {
      //get item in data list

      const itemIndex = this.$store.state.data.findIndex(
        (a) => a._id === this.favoris[id]._id
      )

      //set fav to false
      this.$store.state.data[itemIndex].favoris = false

      //refresh list
      this.favoris = this.$store.state.data.filter((a) => a.favoris === true)
    },
  },
  directives: {
    dragscroll,
  },
}
</script>

<style lang="scss" scoped>
.favoris {
  cursor: grab;

  .title {
    position: fixed;
    width: 100%;
    padding-bottom: 16px;
    z-index: 1;
    background: linear-gradient(var(--dark) 80%, transparent);
  }

  .list {
    margin-top: 60px;
    position: relative;

    &::after {
      content: '';
      position: fixed;
      bottom: var(--padding);
      width: 100%;
      height: 20px;
      background: linear-gradient(transparent 60%, var(--dark));
      z-index: 10;
    }
  }
}
</style>
