import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'
import VueStar from 'vue-star'

Vue.config.productionTip = false
Vue.use(mdiVue, { icons: mdijs })
Vue.component('VueStar', VueStar)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
