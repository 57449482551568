import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Appareils from '@/views/Appareils.vue'
import Settings from '@/views/Settings.vue'
import Favorites from '@/views/Favorites.vue'
import DetailsAppareil from '@/views/DetailsAppareil.vue'
import Admin from '@/views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/appareils', name: 'Appareils', component: Appareils },
  { path: '/Settings', name: 'Settings', component: Settings },
  { path: '/favorites', name: 'Favorites', component: Favorites },
  { path: '/appareils/:id', name: 'DetailsAppareil', component: DetailsAppareil },
  { path: '/admin', name: 'Admin', component: Admin }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
