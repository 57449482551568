<template>
  <div id="app" :data-theme="$store.state.dark ? 'dark' : 'light'">
    <NavBar />
    <transition name="fade">
      <loading v-show="$store.state.loading" />
    </transition>
    <transition name="fade">
      <div v-if="!$store.state.loading" id="content">
        <Slider />
        <router-view class="router-view" />
      </div>
    </transition>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Slider from '@/components/Slider.vue'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    NavBar,
    Slider,
    Loading,
  },
  /**
   * Get the data from backend when application is mounted
   */
  async mounted() {
    const { res, err } = await this.getData()
    console.log(res)

    if (err) return console.log(err)

    let favoris_storage =
      JSON.parse(localStorage.getItem('projet_iot_favoris')) ?? []

    this.$store.state.data = res.appareils.map((a) => ({
      ...a,
      temp: Math.round(a.temp),
      favoris: favoris_storage.includes(a._id),
      degres_color: this.getColor(a.temp),
      nom: a.nom ? a.nom : `Capitale`,
    }))

    this.$store.state.loading = false
  },
  methods: {
    /**
     * Get color according to the degres
     * @param { Number } degres
     */
    getColor(degres) {
      const degresColors = [
        '#00ECFF',
        '#00FF61',
        '#F0FF00',
        '#FF7400',
        '#FF0000',
      ]

      if (degres <= 0) return degresColors[0]
      else if (degres <= 15) return degresColors[1]
      else if (degres <= 25) return degresColors[2]
      else if (degres <= 30) return degresColors[3]
      else return degresColors[4]
    },
    /**
     * fetch data from backend
     */
    async getData() {
      return new Promise((resolve) => {
        fetch(process.env.VUE_APP_API_URL + 'appareils')
          .then(async (res) => {
            resolve({ res: await res.json() })
          })
          .catch((err) => {
            resolve({ err })
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '@/App.scss';

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;

  background-color: var(--dark);
  color: var(--white);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#content {
  width: 100%;
  padding: var(--padding);
  padding-top: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

h1 {
  margin-top: 0;
  user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--dark-light);
  border-radius: 99px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.router-view {
  overflow-y: scroll;
  position: relative;
}
</style>
