<template>
  <div class="slider">
    <div class="over-gradiant"></div>
    <div
      :class="{ list: true, 'mouse-down': mouse_down }"
      v-dragscroll
      @mousedown="mouse_down = true"
      @mouseenter="mouse_enter()"
      @mouseleave="mouse_leave()"
      ref="html_items"
    >
      <div
        v-for="(item, i) in $store.state.data.filter((a) => a.favoris === true)"
        v-bind:key="i"
        class="item"
        @click="goToAppareil(item._id)"
      >
        <div class="container">
          <div
            class="degres"
            :style="
              `border-color: ${item.degres_color};
          box-shadow: 0 0 4px ${item.degres_color}`
            "
          >
            {{ item.temp }}°
          </div>
          <div class="infos">
            <p>{{ item.nom }}</p>
            <p>{{ item.coord.city }}</p>
          </div>
        </div>
      </div>
      <div class="add">
        <router-link to="/appareils">
          <mdicon name="plus" />
        </router-link>
      </div>
      <div
        class="empty-message"
        v-if="$store.state.data.filter((a) => a.favoris === true).length === 0"
      >
        Ajoutez en favoris des appareils pour les voir ici
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'

export default {
  data: () => ({
    items: [],
    mouse_down: false,
    mouse_hover: false,
    hover_cooldown: false,
    scrolling: true,
    items_size: 226,
  }),
  methods: {
    mouse_up() {
      this.mouse_down = false
    },
    mouse_enter() {
      this.mouse_hover = true
      this.hover_cooldown = true
    },
    mouse_leave() {
      this.hover_cooldown = false
      setTimeout(() => {
        if (!this.hover_cooldown) this.mouse_hover = false
        this.hover_cooldown = false
      }, 500)
    },
    update_scroll() {
      if (!this.scrolling) return

      const div = this.$refs.html_items
      let newpos =
        (Math.floor(div.scrollLeft / this.items_size) + 1) * this.items_size
      const max = Math.round(
        div.scrollWidth - div.getBoundingClientRect().width
      )

      if (div.scrollLeft === max) newpos = 0
      else if (newpos > max) newpos = max

      this.$refs.html_items.scrollTo(newpos, 0)
    },
    update_events() {
      if (!this.mouse_down && !this.mouse_hover) this.scrolling = true
      else this.scrolling = false
    },
    goToAppareil(id) {
      this.$router.push('/appareils/' + id)
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.mouse_up)
    this.timeout = setInterval(() => {
      this.update_scroll()
    }, 2000)
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouse_up)
    clearInterval(this.timeout)
  },
  directives: {
    dragscroll,
  },
  watch: {
    mouse_down() {
      this.update_events()
      setTimeout(() => {
        if (this.mouse_down === false) {
          const div = this.$refs.html_items
          let newpos =
            Math.round(div.scrollLeft / this.items_size) * this.items_size
          const max = div.scrollWidth - div.getBoundingClientRect().width
          if (newpos > max) newpos = max
          this.$refs.html_items.scrollTo(newpos, 0)
        }
      }, 10)
    },
    mouse_hover() {
      this.update_events()
    },
  },
}
</script>

<style lang="scss" scoped>
.slider {
  position: relative;

  .over-gradiant {
    pointer-events: none;
    position: absolute;
    width: calc(100% - 110px);
    height: calc(100% - 18px);
    background: linear-gradient(
      to left,
      var(--dark),
      transparent 10px,
      transparent calc(100% - 10px),
      var(--dark)
    );
    z-index: 1;
  }

  .list {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 16px;
    overflow-x: scroll;
    position: relative;
    padding-left: 10px;
    padding-right: 110px;
    padding-top: 16px;
    padding-bottom: 32px;
    margin-bottom: 16px;
    cursor: grab;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
    }
    border-bottom: var(--border);

    &.mouse-down {
      cursor: grabbing;
      scroll-behavior: unset;
      & > div:not(.add) {
        cursor: grabbing;
        &:hover {
          background-color: transparent !important;
        }
      }
    }

    .item {
      border: var(--border);
      border-radius: 25px;
      display: grid;
      place-items: center;
      padding: 16px;
      cursor: pointer;
      user-select: none;

      .container {
        height: 40px;
        display: flex;

        .degres {
          width: 40px;
          height: 100%;
          border-radius: 99px;
          border: 2px solid;
          display: grid;
          place-items: center;
        }

        .infos {
          width: 140px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding-left: 8px;

          p {
            margin: 0;
            font-weight: 800;

            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
          }
          p:first-child {
            font-size: 12px;
            color: var(--transparent);
          }
        }
      }

      &:hover:not(.add) {
        background-color: var(--dark-light);
      }
    }

    .add {
      display: flex;
      justify-content: flex-end;
      position: fixed;
      right: 32px;
      height: 76px;
      width: 110px;
      border: none;
      background: var(--dark);
      border-radius: 0;
      padding: 0;

      & > a {
        color: var(--white);
        height: 76px;
        width: 76px;
        background-color: var(--dark);
        border: var(--border);
        border-style: dashed;
        border-radius: 25px;
        display: grid;
        place-items: center;
        padding: 16px;
        cursor: pointer;
        transition: all ease-in-out 0.15s;

        &:hover {
          background: var(--dark-light);
        }
      }
    }

    .empty-message {
      height: 76px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
