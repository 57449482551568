<template>
  <form class="modale" @submit="submit">
    <h3>Entrez le mot de passe pour accéder à cette page</h3>
    <input
      type="password"
      placeholder="mot de passe"
      required
      v-model="password"
      autofocus
    />
    <button type="submit" :disabled="!password.length">
      valider

      <mdicon v-if="loading" name="loading" size="18" />
      <mdicon v-else name="check" size="18" />
    </button>
    <p v-if="error" class="error">
      <mdicon name="alert" size="18" />
      {{ error }}
    </p>
  </form>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    password: '',
    error: '',
  }),
  methods: {

    /**
     * Submit the form to the backend
     */
    async submit(e) {
      e.preventDefault()
      this.loading = true
      this.error = ''
      const pwd = this.password
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pwd }),
      }

      fetch(process.env.VUE_APP_API_URL + 'admin', opts)
        .then(async (res) => {
          res = await res.json()
          if (res.err) {
            this.error = res.err
          } else {
            if (res.valid) {
              this.$store.state.password = pwd
              this.$store.state.connected = true
            }
            else this.error = 'Le mot de passe est incorrect'
          }
        })
        .catch((err) => {
          this.error = err
        })
        .finally(() => {
          this.loading = false
          this.password = ''
        })
    },
  },
}
</script>

<style scoped lang="scss">
.modale {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  border-radius: 16px;
  width: 100%;
  max-width: 400px;

  background-color: var(--dark);
  border: 2px solid var(--dark-light);
  box-shadow: 0 0 5px var(--grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;

  & > h3 {
    margin: 0;
  }

  & > input {
    width: 200px;
    background-color: var(--dark-light);
    border: 2px solid var(--transparent);
    border-radius: 8px;
    padding: 8px;
    outline: none;
    color: var(--white);

    &:focus {
      border-color: var(--primary);
    }
  }

  & > button {
    background-color: var(--primary);
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    outline: none;
    color: #ececf1;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.1s;
    font-size: 15px;

    & > .mdi {
      position: relative;
      top: -2px;
      margin-left: 8px;

      &:is(.mdi-loading) {
        top: 0px;
        animation: 2s linear infinite loading;
        @keyframes loading {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    &:active {
      transform: scale(0.97);
    }

    &:disabled {
      background-color: var(--dark-light);
      cursor: not-allowed;
    }
  }

  .error {
    position: absolute;
    bottom: 8px;
    margin: 0;
    font-size: 12px;
    color: red;
  }
}
</style>
