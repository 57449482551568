<template>
  <WorldChart />
</template>

<script>
import WorldChart from '@/components/WorldChart.vue'

export default {
  name: 'Home',
  components: {
    WorldChart,
  },
}
</script>
