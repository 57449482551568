<template>
  <div class="item">
    <div class="container">
      <router-link :to="`/appareils/${appareil._id}`" class="link">
        <div class="img">
          <img
            :src="
              `https://openweathermap.org/img/wn/${appareil.weather.icon}.png`
            "
            alt="weather-icon"
          />
        </div>

        <div
          class="degres"
          :style="
            `border-color: ${appareil.degres_color};
          box-shadow: 0 0 4px ${appareil.degres_color}`
          "
        >
          {{ appareil.temp }}°
        </div>
        <div class="infos">
          <p>
            <span class="scalable">{{ appareil.nom }}</span>
            <span class="tag">{{
              appareil.nom === 'Capitale' ? 'API' : 'ARDUINO'
            }}</span>
          </p>
          <p class="scalable">{{ appareil.coord.city }}</p>
        </div>
      </router-link>
      <div class="fav">
        <button @click="favoris()">
          <mdicon
            :class="{ favoris: appareil.favoris }"
            :name="!appareil.favoris ? 'heart-outline' : 'cards-heart'"
            size="30"
          />
          <VueStar :class="{ animate: animated }"></VueStar>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appareil: { Object },
  },
  data: () => ({
    animated: false,
  }),
  methods: {

    /**
     * Toggle favorite item 
     */
    favoris() {
      if (this.appareil.favoris !== true) {
        this.animated = true
        setTimeout(() => {
          this.animated = false
        }, 800)
      }

      this.$emit('like')

      let favoris_storage =
        JSON.parse(localStorage.getItem('projet_iot_favoris')) ?? []
      if (this.appareil.favoris) favoris_storage.push(this.appareil._id)
      else
        favoris_storage = favoris_storage.filter((a) => a !== this.appareil._id)
      localStorage.setItem(
        'projet_iot_favoris',
        JSON.stringify(favoris_storage)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  &:not(:last-child) {
    border-bottom: var(--border);
  }
  padding: 16px;
  user-select: none;
  margin-bottom: 8px;

  .container {
    display: flex;

    & > .link {
      height: 50px;
      display: flex;
      text-decoration: none;
      width: 400px;
      max-width: calc(100% - 50px);

      .img {
        border-radius: 15px;
        margin-right: 8px;
        background-color: var(--dark-light);
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
          filter: drop-shadow(0 0 2px rgba(#fff, 0.8));
        }
      }

      .degres {
        width: 50px;
        height: 100%;
        border-radius: 15px;
        border: 2px solid;
        display: grid;
        place-items: center;
        color: var(--white);
        font-size: 18px;
      }

      .infos {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-left: 8px;
        width: calc(100% - 108px);

        p {
          margin: 0;
          font-weight: 800;
          color: var(--white);
        }

        .scalable {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        p:first-child {
          font-size: 12px;
          color: var(--transparent);
        }
      }
    }

    .fav {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: auto;
      width: 50px;

      button {
        height: 50px;
        position: relative;
        border: none;
        background: none;
        color: var(--red);
        cursor: pointer;
        padding: 0;

        .favoris {
          color: var(--red);
        }
      }
    }
  }

  .tag {
    border: 2px solid var(--dark-light);
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 12px;
    user-select: all !important;
    margin-left: 16px;
  }
}
</style>

<style lang="scss">
.VueStar {
  width: 60px;
  height: 60px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.VueStar__ground,
.VueStar__decoration {
  width: 100% !important;
  height: 100% !important;
}
.VueStar__decoration {
  background-size: cover !important;
}

.animate {
  .VueStar__decoration {
    background-position: 100% 0;
    transition: background-position 0.7s steps(16);
  }
}
</style>
