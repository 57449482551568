<template>
  <div style="height: 100%">
    <div class="table" v-if="$store.state.connected">
      <div class="head wrapper">
        <div></div>
        <div>Nom</div>
        <div>Ville</div>
        <div>Temperature</div>
        <div>Heure</div>
        <div>NB données</div>
        <div>Type</div>
        <div>Actif</div>
      </div>
      <div
        v-for="(item, i) in data"
        :key="i"
        :class="{
          item: true,
          wrapper: true,
          edit: editing(i),
        }"
      >
        <div>
          <button @click="goToAppareil(item._id)" name="launch">
            <mdicon name="launch" size="18" />
          </button>
          <button @click="show_delete_Modal(i)" name="delete">
            <mdicon name="delete" size="18" />
          </button>
          <button
            name="pencil"
            v-if="item.nom !== 'Capitale'"
            @click="
              () => {
                if (editing(-1) || editing(i)) edit(i)
              }
            "
          >
            <mdicon v-if="editing(i)" name="check" size="18" />
            <mdicon v-else name="pencil" size="18" />
          </button>
        </div>
        <div
          :contenteditable="editing(i)"
          :ref="'nom_' + i"
          tabindex="1"
          @keydown.enter.prevent="
            () => {
              if (editing(-1) || editing(i)) edit(i)
            }
          "
          @keydown.esc.prevent="
            () => {
              item_edited = -1
              $refs['nom_' + i][0].innerText = data[i].nom
            }
          "
        >
          {{ item.nom }}
        </div>
        <div>{{ item.coord.city }}</div>
        <div>{{ item.temp }}°</div>
        <div>{{ get_hour(item.timezone) }}</div>
        <div>{{ item.temperatures.length }}/{{ 24 * 7 }}</div>
        <div>{{ item.nom === 'Capitale' ? 'API' : 'ARDUINO' }}</div>
        <div v-if="is_active(item)" class="active green"></div>
        <div v-else class="active red"></div>
      </div>
    </div>
    <AdminModale v-else />
    <div id="deleteModale" v-if="item_deleting !== -1">
      <div class="card" v-click-outside="hideDeleteModale">
        <h3>
          Voulez vous vraiment suprimmer {{ data[item_deleting].nom }} |
          {{ data[item_deleting].coord.city }} ?
        </h3>
        <div class="actions">
          <button class="cancel" @click="hideDeleteModale">annuler</button>
          <button class="delete" @click="delete_appareil">suprimmer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminModale from '@/components/AdminModale.vue'
import vClickOutside from 'v-click-outside'

export default {
  components: { AdminModale },
  directives: { clickOutside: vClickOutside.directive },
  data: () => ({
    item_edited: -1,
    item_deleting: -1,
    data: [],
  }),
  beforeMount() {
    this.data = this.$store.state.data
  },
  methods: {
    /**
     * get time according to timezone
     * @param { Number } timezone
     */
    get_hour(timezone) {
      if (timezone === undefined) return ''
      const date = new Date()
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
      date.setTime(date.getTime() + timezone * 1000)
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },

    /**
     * check if an item is currently active
     * @param { * } item
     */
    is_active(item) {
      if (!item.temperatures.length) return false

      // offset: ~ 1h
      const offset = 1000 * 60 * 60 * 1.1

      let item_date = item.temperatures[item.temperatures.length - 1].date
      item_date = new Date(item_date)
      item_date.setTime(item_date.getTime() - item.timezone * 1000)

      const current_date = new Date()
      current_date.setMinutes(
        current_date.getMinutes() + current_date.getTimezoneOffset()
      )

      return current_date - item_date < offset ? true : false
    },

    /**
     * Edit item name
     * @param { Number } i appareil index
     */
    edit(i) {
      if (this.item_edited === i) {
        this.item_edited = -1

        const new_name = this.$refs['nom_' + i][0].innerText
        this.$store.state.loading = true

        //fetch
        const opts = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pwd: this.$store.state.password, new_name }),
        }
        const item_id = this.data[i]._id

        fetch(process.env.VUE_APP_API_URL + 'edit/' + item_id, opts)
          .then(async (res) => {
            res = await res.json()

            if (res.err) {
              console.log(res.err)
            } else {
              this.refresh(res.appareils)
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.$store.state.loading = false
          })
      } else {
        this.item_edited = i
        this.$refs['nom_' + i][0].focus()
      }
    },

    /**
     * Check if an item is currently edited
     * @param { Number } i appareil index
     */
    editing(i) {
      return this.item_edited === i
    },

    /**
     * Show the delete modale for an appreil
     * @param { Number } i appareil index
     */
    show_delete_Modal(i) {
      if (this.item_edited === i) this.item_edited = -1
      this.item_deleting = i
    },

    /**
     * Delete the appareil selectioned
     */
    delete_appareil() {
      this.$store.state.loading = true

      //fetch
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pwd: this.$store.state.password }),
      }
      const item_id = this.data[this.item_deleting]._id

      fetch(process.env.VUE_APP_API_URL + 'delete/' + item_id, opts)
        .then(async (res) => {
          res = await res.json()

          if (res.err) {
            console.log(res.err)
          } else {
            console.log(res)
            this.refresh(res.appareils)
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.$store.state.loading = false
          this.hideDeleteModale()
        })
    },

    /**
     * Go the details page of an appareil
     * @param { Number } id appareil id
     */
    goToAppareil(id) {
      this.$router.push('/appareils/' + id)
    },

    /**
     * Refresh all the data
     */
    refresh(appareils) {
      function getColor(degres) {
        const degresColors = [
          '#00ECFF',
          '#00FF61',
          '#F0FF00',
          '#FF7400',
          '#FF0000',
        ]

        if (degres <= 0) return degresColors[0]
        else if (degres <= 15) return degresColors[1]
        else if (degres <= 25) return degresColors[2]
        else if (degres <= 30) return degresColors[3]
        else return degresColors[4]
      }

      let favoris_storage =
        JSON.parse(localStorage.getItem('projet_iot_favoris')) ?? []

      this.$store.state.data = appareils.map((a) => ({
        ...a,
        temp: Math.round(a.temp),
        favoris: favoris_storage.includes(a._id),
        degres_color: getColor(a.temp),
        nom: a.nom ? a.nom : `Capitale`,
      }))

      this.data = this.$store.state.data
    },

    /**
     * Hide delete modale
     */
    hideDeleteModale() {
      this.item_deleting = -1
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  padding: 4px 16px;
  gap: 8px;
  align-items: center;
  height: 40px;
  & > div {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > div:first-child {
    width: 700px;
    display: flex;

    button[name='pencil']:hover {
      color: var(--primary);
      background: rgba(#4c49f2, 0.1);
    }
    button[name='delete']:hover {
      color: red;
      background: rgba(red, 0.1);
    }
  }
}

.table {
  position: relative;
  padding: 0 8px;

  .head {
    gap: 0;
    top: 158px;
    position: fixed;
    width: calc(100% - 160px);
    background-color: var(--dark-light);
    border-radius: 8px;
    padding: 12px 16px;
    z-index: 1;
  }

  & > div:not(.head) {
    color: var(--transparent);

    &:hover {
      color: var(--white);
      border-radius: 8px;
    }
  }

  & > div:nth-child(2) {
    margin-top: 57px;
  }

  button {
    all: unset;
    display: grid;
    place-items: center;
    border-radius: 999px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
      background-color: var(--dark-light);
    }
  }
}

.active {
  position: relative;
  height: 25px;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 99px;
  }
  &.green::before {
    background-color: #3ad570;
  }
  &.red::before {
    background-color: red;
  }
}

div[tabindex='1'] {
  outline: none;
}
div[contenteditable='true'] {
  border: 2px solid var(--transparent);
  border-radius: 6px;
  padding-left: 8px;
  outline: none;
  width: calc(100% - 78px);
}

#deleteModale {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.4);
  display: grid;
  place-items: center;
  z-index: 1;

  .card {
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    max-width: 400px;

    background-color: var(--dark);
    border: 2px solid var(--dark-light);
    box-shadow: 0 0 5px var(--grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    text-align: center;
    user-select: none;

    & > h3 {
      margin: 0;
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > button {
        border: none;
        border-radius: 8px;
        width: 120px;
        height: 40px;
        text-align: center;

        outline: none;
        color: #ececf1;
        cursor: pointer;
        transition: all ease-in-out 0.1s;
        font-size: 15px;

        &:active {
          transform: scale(0.97);
        }

        &.cancel {
          background-color: var(--dark-light);
          &:hover {
            background-color: var(--transparent);
          }
        }

        &.delete {
          background-color: rgba(#ff0000, 0.3);
          &:hover {
            background-color: rgba(#ff0000, 0.8);
          }
        }
      }
    }
  }
}
</style>
