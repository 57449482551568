<template>
  <div class="appareils" v-dragscroll>
    <h1 class="title">Appareils</h1>
    <div class="list">
      <Appareil
        v-for="(item, i) in appareils"
        v-bind:key="i"
        :appareil="item"
        @like="like(i)"
      />
    </div>
  </div>
</template>

<script>
import Appareil from '@/components/Appareil.vue'
import { dragscroll } from 'vue-dragscroll'

export default {
  components: { Appareil },
  data: () => ({
    appareils: [],
  }),
  beforeMount() {
    this.appareils = this.$store.state.data
  },
  methods: {
    /**
     * Toggle favorite item 
     * @param { Number } id appareil index
     */
    like(id) {
      //toggle fav
      const fav = this.appareils[id].favoris === true ? false : true
      this.$store.state.data[id].favoris = fav

      //refresh list
      this.$set(this.appareils, this.$store.state.data)
    },
  },
  directives: {
    dragscroll,
  },
}
</script>

<style lang="scss" scoped>
.appareils {
  cursor: grab;

  .title {
    position: fixed;
    width: 100%;
    padding-bottom: 16px;
    z-index: 1;
    background: linear-gradient(var(--dark) 80%, transparent);
  }

  .list {
    margin-top: 60px;
    position: relative;

    &::after {
      content: '';
      position: fixed;
      bottom: var(--padding);
      width: 100%;
      height: 20px;
      background: linear-gradient(transparent 60%, var(--dark));
      z-index: 10;
    }
  }
}
</style>
