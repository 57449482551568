<template>
  <div class="details">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <mdicon name="arrow-left" />
      </div>
      <h1>
        <span>{{ appareil.nom }} </span>
        <span>{{ appareil.coord.city }}</span>
      </h1>
      <div class="tag">
        {{ appareil.nom === 'Capitale' ? 'API' : 'ARDUINO' }}
      </div>
    </div>
    <div class="content">
      <div id="chartdiv" ref="chartdiv"></div>
      <div class="footer">
        <div class="weather">
          <img
            :src="
              `https://openweathermap.org/img/wn/${appareil.weather.icon}@2x.png`
            "
            alt="weather-icon"
          />
          <p
            :style="
              `border-color: ${appareil.degres_color};
              box-shadow: 0 0 4px ${appareil.degres_color}`
            "
          >
            {{ appareil.temp }}°
          </p>
        </div>
        <div class="position">
          <p>Position</p>
          <div>
            <span class="tag">L {{ appareil.coord.lon }}</span>
            <span class="tag">l {{ appareil.coord.lat }}</span>
          </div>
        </div>
        <div class="wind">
          <p>Vent</p>
          <div>
            <span>{{ appareil.wind.speed }} m/s</span>
            <span class="deg">
              <span :style="`transform: rotate(${appareil.wind.deg}deg)`"
                ><mdicon name="arrow-right" size="20"/></span
            ></span>
          </div>
        </div>
        <div class="rain" v-if="appareil.rain">
          <p>Précipitations</p>
          <p class="value">
            <span class="tag">{{ appareil.rain }} mm</span> en 1h
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR'

am4core.useTheme(am4themes_animated)

export default {
  data: () => ({
    appareil: {},
  }),
  mounted() {
    am4core.ready(() => {
      this.init_chart()
    })
  },
  methods: {
    
    /**
     * Init the chart
     */
    init_chart() {
      const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
      chart.language.locale = am4lang_fr_FR

      chart.data = this.appareil.temperatures.map((t) => ({
        date: new Date(t.date),
        temp: t.temp,
      }))
      chart.dateFormatter.dateFormat = 'dd-MM-yyyy HH:mm'

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.minGridDistance = 50
      dateAxis.cursorTooltipEnabled = false
      dateAxis.dateFormats.setKey('hour', '[bold]HH:mm')
      dateAxis.dom.classList.add('date-axis')
      dateAxis.baseInterval = { timeUnit: 'hour', count: 1 }

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.title.text = 'Températures'
      valueAxis.cursorTooltipEnabled = false

      const series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'temp'
      series.dataFields.dateX = 'date'

      series.tooltipHTML = '<div class="tooltip">{dateX} | {valueY}°</div>'
      series.tooltip.background.opacity = 0
      series.tooltip.pointerOrientation = 'vertical'
      series.strokeWidth = 4
      series.stroke = '#ffffff'
      series.smoothing = 'monotoneX'

      const ranges = [
        { start: -100, end: 0, color: '#00ECFF' },
        { start: 0, end: 15, color: '#00FF61' },
        { start: 15, end: 25, color: '#F0FF00' },
        { start: 25, end: 30, color: '#FF7400' },
        { start: 30, end: 100, color: '#FF0000' },
      ]

      for (let range of ranges) {
        const seriesRange = valueAxis.createSeriesRange(series)
        seriesRange.value = range.start
        seriesRange.endValue = range.end
        seriesRange.contents.stroke = am4core.color(range.color)
        seriesRange.contents.fill = seriesRange.contents.stroke
      }

      chart.cursor = new am4charts.XYCursor()

      this.chart = chart
    },
    update_appareil() {
      this.appareil = this.$store.state.data.find(
        (element) => element._id === this.$route.params.id
      )
    },
  },
  beforeMount() {
    this.update_appareil()
  },
  beforeDestroy() {
    if (this.chart) this.chart.dispose()
  },
  watch: {
    $route() {
      this.update_appareil()
      if (this.chart) this.chart.dispose()
      this.init_chart()
    },
  },
}
</script>

<style lang="scss" scoped>
.details {
  overflow-x: hidden;
  .header {
    display: flex;
    height: 70px;
    align-items: center;

    .back {
      background: var(--dark-light);
      border: 2px solid var(--dark-light);
      width: 50px;
      height: 50px;
      border-radius: 10px;
      display: grid;
      place-items: center;
      margin-right: 16px;
      cursor: pointer;

      &:hover {
        background: var(--dark);
        border-color: var(--dark-light);
      }
    }

    h1 {
      margin: 0;

      & > span:first-child {
        text-transform: capitalize;
        color: var(--primary);
      }
      & > span:last-child {
        font-size: 25px;
      }
    }
  }

  #chartdiv {
    width: 100%;
    height: 500px;
  }

  .footer {
    height: 110px;
    display: flex;
    gap: 16px;
    user-select: none;
    justify-content: center;

    & > div {
      background-color: var(--dark-light);
      border-radius: 40px;
    }

    .weather {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;

      img {
        width: 100px;
        filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8));
      }

      p {
        margin: 0;
        border: 2px solid;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        display: grid;
        place-items: center;
        font-size: 20px;
        background: var(--dark);
      }
    }

    .position {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      gap: 16px;
      width: 280px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .tag {
        margin: 0;
        border-color: var(--dark);
        font-size: 15px;
      }
    }

    .wind {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      gap: 16px;

      & > p {
        font-size: 30px;
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .deg {
        color: var(--primary);
        background-color: var(--dark);
        height: 38px;
        width: 38px;
        display: grid;
        place-items: center;
        border-radius: 8px;
      }
    }

    .rain {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 250px;

      & > p {
        font-size: 30px;
        margin: 0;
      }

      & > p.value {
        font-size: 15px;
      }

      .tag {
        font-size: 15px;
      }
    }

    .tag {
      color: var(--primary);
      background-color: var(--dark);
    }
  }

  .tag {
    border: 2px solid var(--dark-light);
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 12px;
    margin-left: 16px;
    user-select: all !important;
  }

  @media screen and (max-height: 870px) {
    #chartdiv {
      height: calc(100vh - 370px);
      min-height: calc(100vh - 260px);
    }
  }
  @media screen and (max-height: 650px) and (min-width: 1000px) {
    .content {
      display: flex;
      flex-direction: row-reverse;
      #chartdiv {
        height: calc(100vh - 260px);
        min-height: calc(100vh - 260px);
        width: 100%;
      }

      .footer {
        width: 450px;
        height: calc(100vh - 260px);
        display: block;
        overflow-y: scroll;

        & > div {
          margin-bottom: 16px;
          height: 110px;
          width: calc(100% - 8px);
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .content {
      .footer {
        display: block;

        & > div {
          height: 110px !important;
          margin-bottom: 16px;
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#chartdiv {
  .tooltip {
    background: var(--dark-light);
    color: var(--white);
    border-radius: 999px;
    box-shadow: 0 0 5px var(--white);
    margin: 5px;
    padding: 8px 16px;
  }

  text {
    fill: var(--white);
  }

  g[role='button'] {
    & > g {
      fill: var(--primary);
      transition: all ease 0.2s;
    }
    &:hover > g {
      filter: contrast(1.2);
    }
  }

  [aria-labelledby]:not([tabindex='0']) {
    display: none;
  }

  .date-axis {
    tspan {
      fill: #4c49f2;
      font-weight: bold;
    }
    tspan[style='font-weight:bold'] {
      fill: var(--white);
      font-weight: 400 !important;
    }
  }
}
</style>
