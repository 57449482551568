<template>
  <div class="toggle" @click="toggle()" :data-value="value ? 'true' : 'false'">
    <div>
      <mdicon v-if="value" name="weather-night" width="22" />
      <mdicon v-else name="lightbulb-on" width="22" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    value: false,
  }),
  methods: {
    toggle() {
      this.$store.state.dark = !this.$store.state.dark
    },
  },
  beforeMount() {
    this.value = this.$store.state.dark
  },
  watch: {
    '$store.state.dark'(dark_mode) {
      this.value = dark_mode
      localStorage.setItem('projet_iot_dark_mode', this.value)
    },
  },
}
</script>

<style lang="scss">

.toggle {
  background-color: var(--white);
  border-radius: 999px;
  width: 40px;
  height: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  & > div {
    background-color: var(--dark);
    border: 3px solid var(--primary);
    border-radius: 999px;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    position: absolute;
    left: -13px;
    transition: left ease-in-out 0.2s;
  }

  &[data-value='true'] > div {
    left: calc(100% - 27px);
  }
}

</style>
