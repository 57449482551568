<template>
  <div class="loading">
    <LottieAnimation :json="loading" :width="200" />
  </div>
</template>

<script>
import LottieAnimation from '@/components/LottieAnimation.vue'
import loading from '@/assets/loading.json'

export default {
  components: {
    LottieAnimation,
  },
  data: () => ({
    loading: loading,
  }),
}
</script>

<style scope lang="scss">
.loading {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: absolute;

  & > * {
    width: 200px;
  }

  .loader {
    width: 100px;
    height: 100px;
    border: 3px solid var(--primary);
    border-radius: 999px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 50% 50%);
    animation: 2s linear 0s infinite rotate;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
