import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dark: (localStorage.getItem('projet_iot_dark_mode') === 'false') ? false : true,
    data: [],
    loading: true,
    connected: false,
    password: '',
  },
  actions: {
  },
  modules: {
  },
})
